<template>
    <!-- <div> -->

    <v-dialog
        v-model="dialog.show"
        transition="dialog-bottom-transition"
        fullscreen
        persistent
    >
        <v-card class="">
            <!-- in ios add pt-3 and height="80" -->
            <v-toolbar dark color="primary" class="rounded-0"
            
            :class="$ios_toolbar_class"
            :height="$ios_toolbar_height"
            >
                <v-btn
                    icon
                    dark
                    @click="
                        dialog.show = false;
                        $emit('refresh');
                    "
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$tr("Employee")}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="primary"
                class=""
                rounded
                grow
            >
                <v-tab href="#tasks-mr" v-if="mr">{{$tr("Tasks")}}</v-tab>
                <v-tab href="#tasks-sr" v-if="sr">{{$tr("Tasks")}}</v-tab>
                <v-tab href="#profile">{{$tr("Profile")}}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="pt-6">
                <v-tab-item value="tasks-mr" v-if="mr">
                    <mr-bundles
                        ref="mr_bundles"
                        :employee="dialog.employee"
                        :actions="actions"
                    ></mr-bundles>
                </v-tab-item>
                <v-tab-item value="tasks-sr" v-if="sr">
                    <sr-tasks
                        ref="sr_tasks"
                        :employee="dialog.employee"
                        :actions="actions"
                    ></sr-tasks>
                </v-tab-item>
                <v-tab-item value="profile">
                    <v-card flat>
                        <v-card-text>
                            <profile
                                :employee="dialog.employee"
                                :actions="actions"
                                @deleted="
                                    dialog.show = false;
                                    $emit('refresh');
                                "
                                @updated="
                                    dialog.show = false;
                                    $emit('refresh');
                                "
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
    <!-- </div> -->
</template>
<script>
import { sync } from "vuex-pathify";
export default {
    data() {
        return {
            dialogs: {},
            tab: 0,
            fetching_tasks: false,
        };
    },
    watch: {
        async tab(v) {
            if (v == "tasks-mr") {
                await this.$nextTick();
                await this.$nextTick();
                
                await this.$refs.mr_bundles?.refresh_mr_bundles?.(null, true);
            }else if(v == "tasks-sr"){
                await this.$nextTick();
                await this.$nextTick();
                await this.$refs.sr_tasks?.refresh_sr_tasks?.(null, true);
            }
        },
        async show(val) {
            if (val) {
                this.$set(this, "tab", "profile");
            }
        },
    },
    methods: {},
    computed: {
        ...sync("user", {
            loading_cover: "visualization@loading_cover",
        }),
        show() {
            return this.dialog.show;
        },
        not_admin() {
            return !this.dialog.employee?.role?.includes("admin");
        },
        sr() {
            return this.dialog.employee?.role == "sr";
        },
        mr() {
            return this.dialog.employee?.role == "mr";
        },
    },
    props: {
        actions: {
            type: Object,
            required: true,
            defualt: () => ({
                show: false,
            }),
        },
        dialog: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardView: () =>
            import(
                /* webpackChunkName: "card_view" */
                "./card_view.vue"
            ),
        MrBundleDetailsDialog: () =>
            import(
                /* webpackChunkName: "bundle_details_dialog" */
                "./mr_bundle_details_dialog.vue"
            ),
        Profile: () =>
            import(
                /* webpackChunName: "profile" */
                "./profile.vue"
            ),
        MrBundles: () => import("./mr_bundles_tab/Index.vue"),
        SrTasks: () => import("./sr_tasks_tab/Index.vue"),
    },
};
</script>

<style>
.emp1 {
    min-height: 100px;
    border-radius: 0;
    top: 50%;
}
</style>